<template>
  <v-app>
    <v-sheet class="py-12 px-6" color="#F8ECDC" height="100%">
      <Overlays :is-absolute="true" :is-show="absoluteOverlayStatus"></Overlays>
      <div class="mt-12 mb-6" style="font-size: 30px">
        <span>注文履歴</span>
      </div>
      <div>
        <v-tabs
          v-model="toTab"
          color="#ff6e40"
          hide-slider
          active-class="selectedTab"
          background-color="#FFF8E1"
          slider-color="#fff"
          elevation="6"
        >
          <v-tab href="#list" style="min-width: 35%" @click="doClick1"> 注文履歴 </v-tab>
          <v-tab-item value="list" background-color="#F8ECDC">
            <List :list1="list1" />
          </v-tab-item>
          <v-tab href="#undispatched" style="min-width: 30%" @click="doClick2"> 未決済 </v-tab>
          <v-tab-item value="undispatched">
            <Undispatched :list2="list2" />
          </v-tab-item>
          <v-tab href="#cancel" style="min-width: 35%" @click="doClick3">
            <span v-if="!$vuetify.breakpoint.smAndUp">キャンセ...</span>
            <span v-if="$vuetify.breakpoint.smAndUp">キャンセルした注文</span>
          </v-tab>
          <v-tab-item value="cancel">
            <Cancel :list3="list3" />
          </v-tab-item>
        </v-tabs>
      </div>
    </v-sheet>
  </v-app>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'
import Overlays from '@/components/Overlays.vue'
import List from './components/List.vue'
import Undispatched from './components/Undispatched.vue'
import Cancel from './components/Cancel.vue'

export default {
  components: {
    List,
    Undispatched,
    Cancel,
    Overlays,
  },
  data: () => ({
    toTab: 'list',
    list1: '',
    list2: '',
    list3: '',
    tab: null,
    items: [
      {
        tab: '注文履歴',
      },
      {
        tab: '未決済',
      },
      {
        tab: 'キャンセル注文',
      },
    ],
  }),
  computed: {
    ...mapState('orderStore', ['orderList']),
    ...mapState('app', ['absoluteOverlayStatus']),
  },
  mounted() {
    if (this.$route.query.value) {
      this.toTab = this.$route.query.value
    }
    if (this.toTab === 'list') {
      this.doClick1()
    }
    if (this.toTab === 'undispatched') {
      this.doClick2()
    }
    if (this.toTab === 'cancel') {
      this.doClick3()
    }
  },
  methods: {
    ...mapActions('orderStore', ['loadOrderList']),
    ...mapMutations('app', ['setAbsoluteOverlayStatus', 'setOverlayStatus']),

    doClick1() {
      this.setAbsoluteOverlayStatus(true)
      this.loadOrderList().then(() => {
        this.list1 = [...this.orderList]
        this.list1.forEach((details, i) => {
          this.list1[i].details.forEach((il, index) => {
            if (il.images) {
              this.list1[i].details[index].image = `${il.imgUrl_prefix}/${il.images
                .replaceAll('"', '')
                .split(',')[0]
                .replace(/^\//g, '')}`
            }
          })
        })
        this.setAbsoluteOverlayStatus(false)
      })
    },
    doClick2() {
      this.setAbsoluteOverlayStatus(true)
      this.loadOrderList({
        type: 'UNDELIVERED',
      }).then(() => {
        this.list2 = [...this.orderList]
        this.list2.forEach((details, i) => {
          this.list2[i].details.forEach((il, index) => {
            if (il.images) {
              this.list2[i].details[index].image = `${il.imgUrl_prefix}/${il.images
                .replaceAll('"', '')
                .split(',')[0]
                .replace(/^\//g, '')}`
            }
          })
        })
        this.setAbsoluteOverlayStatus(false)
      })
    },
    doClick3() {
      this.setAbsoluteOverlayStatus(true)
      this.loadOrderList({
        type: 'CANCELED',
      }).then(() => {
        this.list3 = [...this.orderList]
        this.list3.forEach((details, i) => {
          this.list3[i].details.forEach((il, index) => {
            if (il.images) {
              this.list3[i].details[index].image = `${il.imgUrl_prefix}/${il.images
                .replaceAll('"', '')
                .split(',')[0]
                .replace(/^\//g, '')}`
            }
          })
        })
        this.setAbsoluteOverlayStatus(false)
      })
    },
  },
}
</script>

<style scoped>
.selectedTab {
  background-color: #fff;
}
.v-text-field--outlined >>> fieldset {
  border-color: #ff6e40;
  border-width: 2px;
}
</style>
