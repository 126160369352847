<template>
  <div class="pt-3">
    <template v-if="apierror.status == 'error'">
      <div v-for="msg of apierror.messages" :key="msg">
        <v-row class="ml-6 mb-3 ma-3">
          <span style="color: red">* {{ msg }} </span>
        </v-row>
      </div>
    </template>
    <div v-if="displayLists.length === 0" class="d-flex align-center justify-center" style="height: 500px">
      <span style="font-size: 20px; text-align: center; margin-top: -60px; color: #ff6e40; font-weight: 500"
        >注文された注文が0件です。
      </span>
    </div>
    <v-card v-for="(item, index) in displayLists" :key="index" outlined color="rgb(0, 0, 0, 0)">
      <v-row class="px-3" style="background-color: #ebe9e8">
        <v-col cols="12" sm="4" md="2" lg="2" xl="2">
          <div>
            <strong>注文日</strong>
          </div>
          <div>
            <span>{{ item.order_dt }}</span>
          </div>
        </v-col>
        <v-col cols="12" sm="8" md="2" lg="1" xl="1">
          <div>
            <strong>合計</strong>
          </div>
          <div>
            <span
              >￥{{
                item.order_total ? item.order_total.toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,') : ''
              }}</span
            >
          </div>
        </v-col>
        <v-col cols="12" sm="4" md="2" lg="3" xl="3">
          <div style="margin-top: -1px">
            <div>
              <strong>お届け先</strong>
            </div>
            <div></div>
            <div class="mousemove">
              <span style="color: #ff6e40"
                >{{ item.addr_name }}<v-icon style="margin-top: -5px">{{ icons.mdiMenuDown }}</v-icon></span
              >
              <div class="bottomtip">
                <span>{{ item.addr_zip }} {{ item.addr_addr1 }} {{ item.addr_addr2 }}</span>
              </div>
            </div>
          </div>
        </v-col>
        <v-col cols="12" sm="4" md="2" lg="2" xl="2">
          <div>
            <strong>予定発送日</strong>
          </div>
          <div>
            <span>{{ item.order_ead }}</span>
          </div>
        </v-col>
        <v-col cols="12" sm="4" md="1" lg="1" xl="1">
          <div>
            <strong>注文状態</strong>
          </div>
          <div>
            <span>{{ getOrderStatus(item) }}</span>
          </div>
        </v-col>
        <v-col cols="12" sm="12" md="3" lg="3" xl="3">
          <div class="d-flex justify-end text-right">
            <strong>注文番号：</strong>
            <span>{{ item.order_no }}</span>
          </div>
          <div class="d-flex align-center justify-end" style="font-size: 15px">
            <div class="print">
              <span style="text-decoration: underline; color: #ff6e40" @click="openDialog(item)">注文詳細</span>
            </div>
            <div>
              <span class="mx-3"> | </span>
            </div>
            <div class="print">
              <span style="text-decoration: underline; color: #ff6e40" @click="delivery(item)">納品書</span>
            </div>
          </div>
        </v-col>
      </v-row>
      <v-sheet class="mb-6 mt-3">
        <v-row class="px-3">
          <v-col cols="12" md="7" lg="8" xl="9">
            <v-row v-for="(obj, index) in item.details" :key="index">
              <v-col cols="12" sm="6" md="5" lg="4">
                <v-img :src="obj.image"></v-img>
              </v-col>
              <v-col cols="12" sm="6" md="7" lg="8">
                <div style="font-size: 20px; font-weight: bold">
                  <span>{{ obj.product_name }}</span>
                </div>
                <div style="font-size: 16px" class="mt-1">
                  <span>数量：</span>
                  <span>{{ obj.order_qty }}</span>
                </div>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" md="5" lg="4" xl="3"> </v-col>
        </v-row>
      </v-sheet>
    </v-card>
    <v-pagination
      v-if="displayLists.length > 0"
      v-model="page"
      class="my-6"
      :length="length"
      :total-visible="7"
      color="#ff6e40"
      @input="pageChange"
    ></v-pagination>
    <showDialog :dialog-list="dialogList" :dialog-item="dialogItem" @closeDialog="closeDialog" />
  </div>
</template>
<script>
import { mapState, mapMutations, mapActions } from 'vuex'
import { mdiMenuDown } from '@mdi/js'
import showDialog from './Dialog.vue'

export default {
  components: {
    showDialog,
  },
  props: ['list1'],
  data: () => ({
    icons: {
      mdiMenuDown,
    },
    apierror: {
      code: '',
      status: '',
      messages: [],
    },
    dialogList: false,
    dialogItem: '',
    selectedList: [],
    month: '',
    count: '',
    displayLists: [],
    page: 1,
    length: 0,
    pageSize: 10,
  }),
  computed: {
    ...mapState('orderStore', ['orderList']),
    getOrderStatus() {
      return function (item) {
        if (item.os_code && item.os_code > 0) {
          if (item.os_code <= 5) return '新規'

          return item.os_desc
        }

        return ''
      }
    },
  },
  watch: {
    list1() {
      this.displayLists = this.list1.slice(this.pageSize * (this.page - 1), this.pageSize * this.page)
      console.log('displayLists', this.displayLists)
      this.length = Math.ceil(this.list1.length / this.pageSize)
    },
  },
  mounted() {},
  methods: {
    ...mapMutations('app', ['setOverlayStatus']),
    ...mapActions('orderStore', ['loadOrderList']),
    ...mapActions('deliveryStore', ['loadDelivery']),

    openDialog(item) {
      this.dialogItem = item
      this.dialogList = true
    },
    closeDialog() {
      this.dialogList = false
    },
    total() {
      let price = 0
      price = this.item.order_total - this.item.shipping_fee - this.item.coupon_discount

      return price.toFixed(0)
    },
    delivery(item) {
      this.setOverlayStatus(true)
      this.loadDelivery(item.order_id)
        .then(() => {
          this.setOverlayStatus(false)
        })
        .catch(error => {
          this.setOverlayStatus(false)
          this.apierror.status = 'error'
          this.apierror.code = error.response.data.code
          this.apierror.messages = error.response.data.message
        })
    },
    pageChange(pageNumber) {
      this.displayLists = this.list1.slice(this.pageSize * (pageNumber - 1), this.pageSize * pageNumber)
      window.scrollTo(0, 0)
    },
  },
}
</script>

<style scoped>
.print :hover {
  cursor: pointer;
}
.bottomtip {
  display: none;
}
.mousemove :hover {
  cursor: pointer;
  text-decoration: underline;
}
.mousemove:hover .bottomtip {
  display: block;
}
</style>
